@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: auto;
}

.GRADIENT {
    background: rgb(0,145,244);
    background: linear-gradient(90deg, rgba(0,145,244,1) 16%, rgba(0,145,244,0) 56%);
}